// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-unauthenticated-js": () => import("./../../../src/pages/Unauthenticated.js" /* webpackChunkName: "component---src-pages-unauthenticated-js" */),
  "component---src-templates-error-page-js": () => import("./../../../src/Templates/ErrorPage.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-help-page-js": () => import("./../../../src/Templates/HelpPage.js" /* webpackChunkName: "component---src-templates-help-page-js" */),
  "component---src-templates-my-templates-page-js": () => import("./../../../src/Templates/MyTemplatesPage.js" /* webpackChunkName: "component---src-templates-my-templates-page-js" */),
  "component---src-templates-preview-questionnaire-page-js": () => import("./../../../src/Templates/PreviewQuestionnairePage.js" /* webpackChunkName: "component---src-templates-preview-questionnaire-page-js" */),
  "component---src-templates-preview-template-page-js": () => import("./../../../src/Templates/PreviewTemplatePage.js" /* webpackChunkName: "component---src-templates-preview-template-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/Templates/PrivacyPage.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-survey-detail-page-js": () => import("./../../../src/Templates/SurveyDetailPage.js" /* webpackChunkName: "component---src-templates-survey-detail-page-js" */),
  "component---src-templates-survey-response-page-js": () => import("./../../../src/Templates/SurveyResponsePage.js" /* webpackChunkName: "component---src-templates-survey-response-page-js" */),
  "component---src-templates-surveys-page-js": () => import("./../../../src/Templates/SurveysPage.js" /* webpackChunkName: "component---src-templates-surveys-page-js" */),
  "component---src-templates-team-detail-page-js": () => import("./../../../src/Templates/TeamDetailPage.js" /* webpackChunkName: "component---src-templates-team-detail-page-js" */),
  "component---src-templates-team-list-page-js": () => import("./../../../src/Templates/TeamListPage.js" /* webpackChunkName: "component---src-templates-team-list-page-js" */),
  "component---src-templates-user-list-page-js": () => import("./../../../src/Templates/UserListPage.js" /* webpackChunkName: "component---src-templates-user-list-page-js" */)
}

